import * as React from 'react';
import RiotBar from '@riotgames/riotbar-react';
import './riotbar.scss';

const RiotBarInstaller = () => {
  return (
    <div className={'app-footer'}>
      <RiotBar product="kr-riotgames" environment={process.env.REACT_APP_RIOTBAR_ENV} locale="ko_KR" />
    </div>
  );
};

export default RiotBarInstaller;
