const api = process.env.REACT_APP_API_URL;

export function sanity_categories() {
  const query = encodeURIComponent(
    `*[_type=="category" && !(_id in path("drafts.**")) && path.current match "group_*"] | order(path.current asc) 
    { _id, title, machineName, 
      "bg":"https://cmsassets.rgpub.io/sanity/"+backdrop.backgroundMedia.sanityImage.asset->path,
      "logo":"https://cmsassets.rgpub.io/sanity/"+backdrop.backgroundMedia.thumbnail.sanityImage.asset->path, 
      "legals": 
        *[_type=="category" && !(_id in path("drafts.**")) && machineName match ^.machineName+"*" &&  !(path.current match "group_*")] | order(machineName) 
        {title, externalTitle, machineName, _id, product, category, "path":path.current}
    }`
  );

  return fetch(`${api}?query=${query}`).then((payload) => payload.json());
}

export function sanity_all_categories() {
  const query = encodeURIComponent(`*[_type=="category" && !(_id in path("drafts.**"))].path.current`);

  return fetch(`${api}?query=${query}`).then((payload) => payload.json());
}

const subquery = ` | order(publishingDates.embargoDate desc) {
      _id, "title":externalTitle, "body": contentBlocks[0].richText.bodyHtml,
      "category":*[_type=="category" && !(_id in path("drafts.**")) && _id == ^.category._ref][0]{title,"path":path.current,"bg":"https://cmsassets.rgpub.io/sanity/"+backdrop.media.sanityImage.asset->path},
      "embargoDate":publishingDates.embargoDate,
      "path":path.current,
      "versions": *[_type=="article" && !(_id in path("drafts.**")) && category._ref == ^.category._ref] | order(publishingDates.embargoDate desc) {_id, "embargoDate":publishingDates.embargoDate}
    }`;

export function sanity_articleById(id) {
  const query = encodeURIComponent(
    `*[_type=="article"  && !(_id in path("drafts.**")) && _id match "*${id}*"]  ${subquery}`
  );

  return fetch(`${api}?query=${query}`).then((payload) => payload.json());
}

export function sanity_articleByPathname(pathname) {
  const query = encodeURIComponent(
    `*[_type=="article"  && !(_id in path("drafts.**")) && path.current == "${pathname}"]  ${subquery}`
  );

  return fetch(`${api}?query=${query}`).then((payload) => payload.json());
}

export function sanity_articleByCategoryPathname(pathname) {
  const query = encodeURIComponent(
    `*[_type=="article"  && !(_id in path("drafts.**")) &&  
    category._ref in *[_type=="category" && !(_id in path("drafts.**")) && 
    "${pathname}" == path.current]._id && (publishingDates.embargoDate <= now() || publishingDates.embargoDate == null)] ${subquery}`
  );

  return fetch(`${api}?query=${query}`).then((payload) => payload.json());
}
