import { useQuery } from '@tanstack/react-query';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';

import './MainPage.scss';
import { Loader } from './Loader';
import { sanity_categories } from './sanityApi';
import { ApiError } from './ApiError';

const MainPage = () => {
  return (
    <div className={'main-page'}>
      <Header title="라이엇 게임즈 법률관련 문서" />
      <div className={'categories'}>
        <CategoryList />
      </div>
    </div>
  );
};

const CategoryList = () => {
  const { isFetching, error, data } = useQuery({ queryKey: ['sanity_categories'], queryFn: sanity_categories });

  if (error) {
    return <ApiError />;
  }

  if (isFetching) {
    return <Loader />;
  }

  return (
    <>
      {data.map(({ logo, title, legals }, index) => (
        <div key={index} className={'category'}>
          <div className={'section'}>
            <div className={'logo'}>
              <img src={logo} alt={'logo'} />
            </div>
            <div className={'title'}>{title}</div>
          </div>
          {legals.map((legal, index) => (
            <div key={index} className={'nav'}>
              <NavLink to={`${legal.path}?title=${legal.title}`}>{legal.title}</NavLink>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default MainPage;
