import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import LegalPage from './LegalPage';
import MainPage from './MainPage';
import RiotBarInstaller from './riotbar-installer';
import './App.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path={'/'} render={(props) => <MainPage {...props} />} />
            <Route path={'/:legaldoc'} render={(props) => <LegalPage {...props} />} />
          </Switch>
        </Router>
        <RiotBarInstaller />
      </QueryClientProvider>
    </div>
  );
}

export default App;
